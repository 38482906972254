import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { api } from '../api';

const Instagram = () => {
	const navigate = useNavigate();
	const [logined, setLogined] = useState(undefined);
	const [status, setStataus] = useState(undefined);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [code, setCode] = useState('');
	const [pageIsClosed, setPageIsClosed] = useState(false);
	const [statusUpdaterVar, setStatusUpdaterVar] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			const { data: status } = await api.status();
			setLogined(status.logined);
			setStataus(status.status);
			setUsername(status.IG_USERNAME);
			setPassword(status.IG_PASSWORD);
		};
		fetchData();
		return () => {
			setPageIsClosed(true);
		};
	}, []);
	useEffect(() => {
		if (!pageIsClosed) {
			const fetchData = async () => {
				const { data: status } = await api.status();
				setLogined(status.logined);
				setStataus(status.status);
				setTimeout(() => {
					setStatusUpdaterVar(!statusUpdaterVar);
				}, 2000);
			};
			fetchData();
		}
	}, [pageIsClosed, statusUpdaterVar]);
	const sendLoginInfo = async () => {
		const { data: send } = await api.sendLoginInfo(username, password);
		console.log(send);
		if (!send.error) alert('Setted');
		else alert(send.message);
	};
	const send2faCode = async () => {
		const { data: send } = await api.send2faCode(code);
		console.log(send);
		if (!send.error) alert('Setted');
		else alert(send.message);
	};
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				Is Logined :{' '}
				{logined === undefined ? 'unknown' : logined ? 'logined' : 'not logined'}
			</div>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				{' '}
				Status : {status === undefined ? 'unknown' : status}
			</div>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				IG Username :
				<input
					type="text"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
				/>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				IG Password :
				<input
					type="text"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				IG 2FA Code :
				<input type="text" value={code} onChange={(e) => setCode(e.target.value)} />
			</div>
			<button onClick={sendLoginInfo} style={{ width: '200px' }}>
				Send Login Info
			</button>
			<button onClick={send2faCode} style={{ width: '200px' }}>
				Send 2FA Code
			</button>
			<button onClick={() => navigate('/dashboard')} style={{ width: '200px' }}>
				Go Dashboard
			</button>
		</div>
	);
};
export default Instagram;
