import * as React from 'react';
import Statistics from '../components/dashboard/statistics';
import Users from '../components/dashboard/users';
import { useNavigate } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { CssBaseline, createTheme } from '@mui/material';

const defaultTheme = createTheme();

export default function Dashboard() {
	const navigate = useNavigate();
	const [isReload, setIsReload] = React.useState(false);

	React.useEffect(() => {
		if (localStorage.getItem('isLogin') === 'false') navigate('/login');
	});
	const handlePageReload = () => {
		setIsReload(!isReload);
	};
	return (
		<ThemeProvider theme={defaultTheme}>
			<CssBaseline />
			<main>
				<Statistics isReload={isReload} />
				<Users handlePageReload={handlePageReload} />
			</main>
		</ThemeProvider>
	);
}
