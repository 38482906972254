import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './routes/home';
import Login from './routes/login';
import Dashboard from './routes/dashboard';
import TestPage from './routes/test';
import NoPage from './routes/nopage';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Instagram from './routes/instagram';

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" exact element={<Home />} />
				<Route path="login" exact element={<Login />} />
				<Route path="dashboard" exact element={<Dashboard />} />
				<Route path="instagram-config" exact element={<Instagram />} />
				<Route path="test" exact element={<TestPage />} />
				<Route path="*" exact element={<NoPage />} />
			</Routes>
		</BrowserRouter>
	);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
