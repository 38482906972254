import axios from 'axios';
const axios1 = axios.create({
	baseURL: 'https://api.vibevibrator.com',
});
export const api = {
	status: async () => axios1.get('/instagram-status?secret=123654789!!!'),
	sendLoginInfo: async (IG_USERNAME, IG_PASSWORD) =>
		axios1.post('/instagram-info?secret=123654789!!!', {
			IG_PASSWORD,
			IG_USERNAME,
		}),
	send2faCode: async (code) =>
		axios1.post('/instagram-code?secret=123654789!!!', {
			code,
		}),
};
