import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import DataView from './data_view';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router';
import { api } from '../../../api';

const Statistics = ({ isReload }) => {
	const [data, setData] = useState({
		freeTime: 0,
		subscription: 0,
		instaFree: 0,
		notActive: 0,
		browser: 0,
	});
	const [logined, setLogined] = React.useState(undefined);
	React.useEffect(() => {
		const fetchData = async () => {
			const { data: status } = await api.status();
			setLogined(status.logined);
		};
		fetchData();
	}, []);
	const navigate = useNavigate();

	useEffect(() => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				// 'authorization': localStorage.getItem('auth')
			},
		};

		let serverURL = process.env.REACT_APP_SERVER_URL + '/players/statistics';
		// console.log(serverURL);

		fetch(serverURL, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					// console.log(JSON.stringify(result));
					if (result.status) {
						setData(result.data);
					}
				},
				(error) => {
					console.log(error);
				}
			);
	}, [isReload]);
	return (
		<Container sx={{ py: 1 }} maxWidth="md">
			<Grid container spacing={1}>
				{Object.keys(data).map((key, index) => (
					<DataView
						key={index}
						lable={
							key === 'freeTime'
								? 'Active Free Time'
								: key === 'subscription'
								? 'Active Subscription'
								: key === 'instaFree'
								? 'Get InstaFree'
								: key === 'notActive'
								? 'Not Active'
								: 'Browser'
						}
						value={data[key]}
					/>
				))}
				<DataView
					lable={`Instagram Config ${
						logined === undefined ? '' : logined ? '🟢' : '🔴'
					}`}
					onClick={() => navigate('/instagram-config')}
					value={''}
				/>
			</Grid>
		</Container>
	);
};

export default Statistics;
