import { Avatar, Box, Grid, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";

const Search = ({ handleSearchAction, handleReloadDataAction }) => {
  const [username, setUsername] = useState("");

  return (
    <Grid container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={11} sm={11} md={11}>
            <TextField
              onChange={(e) => {
                setUsername(e.target.value);
                handleSearchAction(e.target.value);
              }}
              value={username}
              variant="outlined"
              color="secondary"
              type="string"
              sx={{ mb: 1 }}
              fullWidth
              label="Search by username"
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Avatar sx={{ m: 1 }}>
              <IconButton
                aria-label="reload"
                onClick={handleReloadDataAction}
                sx={{
                  bgcolor: "primary.main",
                }}
              >
                <CachedRoundedIcon />
              </IconButton>
            </Avatar>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Search;
