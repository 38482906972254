import React, { useEffect, useState } from "react";
import { Box, Grid, Switch, TextField, Typography } from "@mui/material";

const DataView = ({
  lable,
  value,
  type = "string",
  onDataUpdate,
  isEdit = false,
}) => {
  const [dataValue, setDataValue] = useState(value);
  useEffect(() => {
    setDataValue(value);
  }, [isEdit]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Grid item xs={4} sm={4} md={4}>
        <Typography variant="h7" align="left" gutterBottom>
          <b> {lable}</b>
        </Typography>
      </Grid>
      <Grid item xs={8} sm={8} md={8} >
        <div hidden={isEdit}>
          <Typography
            variant={lable === "Username" ? "h6" : "h7"}
            align="left"
            gutterBottom
          >
            {lable === "Username" ? <b>{value}</b> :  value }
          </Typography>
        </div>
        <div hidden={!isEdit}>
          {type === "string" ? (
            <TextField
              id={lable}
              name={lable}
              fullWidth
              value={dataValue}
              variant="standard"
              onChange={(e) => {
                onDataUpdate(e.target.value);
                setDataValue(e.target.value);
              }}
            />
          ) : (
            <Switch
              checked={dataValue === 1}
              size="small"
              onChange={(e) => {
                setDataValue(e.target.checked ? 1 : 0);
                onDataUpdate(e.target.checked ? 1 : 0);
              }}
            ></Switch>
          )}
        </div>
      </Grid>
    </Box>
  );
};

export default DataView;
