import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';

function DataView({ lable, value, onClick }) {
	return (
		<Grid
			item
			xs={4}
			sm={2.4}
			md={2.4}
			onClick={() => (onClick ? onClick() : undefined)}
		>
			<Card
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					bgcolor: 'primary.main',
				}}
			>
				<CardContent sx={{ flexGrow: 1 }}>
					<Typography
						align="center"
						gutterBottom
						variant="h4"
						component="h2"
						color={'#ffffff'}
						fontWeight="bold"
						width={'100%'}
					>
						{value}
					</Typography>
					<Typography
						align="center"
						variant="h6"
						component="h6"
						color={'#ffffff'}
						fontWeight="bold"
						width={'100%'}
						minHeight={'100%'}
					>
						{lable}
					</Typography>
				</CardContent>
			</Card>
		</Grid>
	);
}

export default DataView;
