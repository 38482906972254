import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import User from "./user";
import Search from "./search";
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";

const Users = ({handlePageReload}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [data, setData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [deleteData, setDeleteData] = useState({
    username: "",
    index: 0,
    isDeleteConfirm: false,
  });

  useEffect(() => {
    console.log("User data loading");
    getPlayersData();
  }, []);

  const handleDeleteAction = (username, index) => {
    var data = {
      username: username,
      index: index,
      isDeleteConfirm: false,
    };
    setDeleteData(data);
    setIsDeleteConfirmation(true);
  };

  const handleUpdateAction = (updatedData, index) => {
    console.log("Updated data", updatedData);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscription: updatedData.subscription,
        freetime: updatedData.freetime,
        createdAt: updatedData.createdAt,
        instagramUser: updatedData.instagramUser,
        usedInstaTime: updatedData.usedInstaTime,
      }),
    };
    setIsLoading(true);

    let serverURL =
      process.env.REACT_APP_SERVER_URL + "/players/" + updatedData.userName;
    // console.log(serverURL);
    fetch(serverURL, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(JSON.stringify(result));
          setIsLoading(false);
          if (result.status) {
            data[index] = updatedData;
            setData(data);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const handleSearchAction = (searchUsername) => {
    console.log("Search", searchUsername);

    if (searchUsername === "") {
      setData(mainData);
    } else {
      setIsLoading(true);

      var searchData = [];
      mainData.forEach((element) => {
        if (
          element.userName
            .toLowerCase()
            .startsWith(searchUsername.toLowerCase())
        )
          searchData.push(element);
      });
      setData(searchData);
      setIsLoading(false);
    }
  };

  const handleReloadDataAction = () => {
    handlePageReload();
    getPlayersData();
  };

  const handleClose = () => {
    setIsDeleteConfirmation(false);
  };

  function getPlayersData() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // 'authorization': localStorage.getItem('auth')
      },
    };
    setIsLoading(true);
    let serverURL = process.env.REACT_APP_SERVER_URL + "/players/";
    // console.log(serverURL);
    fetch(serverURL, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoading(false);

          //   console.log(JSON.stringify(result));
          if (result.status) {
            setMainData(result.data);
            setData(result.data);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  function deletePlayerData(username, index) {
    const requestOptions = {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
      },
    };

    let serverURL = process.env.REACT_APP_SERVER_URL + "/players/" + username;
    // console.log(serverURL);
    setIsLoading(true);
    fetch(serverURL, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoading(false);
          console.log(JSON.stringify(result));
          if (result.status) {
            var newData = [];
            mainData.forEach((value, i) => {
              if (i !== index) newData.push(value);
            });
            setMainData(newData);
            setData(newData);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  return (
    <Container sx={{ py: 1 }} maxWidth="md">
      <Search
        handleSearchAction={handleSearchAction}
        handleReloadDataAction={handleReloadDataAction}
      />
      <div hidden={isLoading}>
        <Grid container spacing={4}>
          {data.map((data, index) => (
            <User
              index={index}
              data={data}
              onDeleteClick={handleDeleteAction}
              onUpdateClick={handleUpdateAction}
            />
          ))}
        </Grid>
        <Dialog
          open={isDeleteConfirmation}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete user?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to delete <b>{deleteData.username}</b>!!!!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                handleClose();
                var newdata = {
                  username: deleteData.username,
                  index: deleteData.index,
                  isDeleteConfirm: true,
                };
                setDeleteData(newdata);
                deletePlayerData(deleteData.username, deleteData.index);
              }}
              autoFocus
              color="error"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div hidden={!isLoading}>
        <LinearProgress />
      </div>
    </Container>
  );
};

export default Users;
