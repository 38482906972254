import { Button, Card, CardActions, CardContent, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DataView from './data_view';

const User = ({ index, data, onDeleteClick, onUpdateClick }) => {
	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		setIsEdit(false);
	}, []);

	const handleEditClick = (e) => {
		setIsEdit(!isEdit);
	};

	return (
		// <React.Fragment>
		<Grid item key={data.userName} xs={12} sm={6} md={6}>
			<Card
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<CardContent sx={{ flexGrow: 1 }}>
					<DataView lable="Username" value={data.userName} />
					<DataView lable="Device ID" value={data.deviceID} />
					<DataView
						lable="Subscription"
						value={data.subscription}
						isEdit={isEdit}
						type="switch"
						onDataUpdate={(v) => {
							data.subscription = v;
							console.log('Sub: ' + v + ' >> ' + data.subscription);
						}}
					/>
					<DataView
						lable="Freetime"
						value={data.freetime}
						isEdit={isEdit}
						type="switch"
						onDataUpdate={(v) => {
							data.freetime = v;
						}}
					/>
					<DataView
						lable="Instagram user"
						value={data.instagramUser}
						isEdit={isEdit}
						onDataUpdate={(v) => {
							data.instagramUser = v;
						}}
					/>
					<DataView
						lable="Used insta time"
						value={data.usedInstaTime}
						isEdit={isEdit}
						type="string"
						onDataUpdate={(v) => {
							data.usedInstaTime = v;
						}}
					/>
					<DataView
						lable="Created At"
						value={data.createdAt}
						isEdit={isEdit}
						type="string"
						onDataUpdate={(v) => {
							data.createdAt = v;
						}}
					/>
				</CardContent>
				<CardActions>
					<div hidden={isEdit}>
						<Button size="small" color="success" onClick={handleEditClick}>
							Edit
						</Button>
						<Button
							size="small"
							color="error"
							onClick={() => {
								onDeleteClick(data.userName, index);
							}}
						>
							Delete
						</Button>
					</div>
					<div hidden={!isEdit}>
						<Button
							size="small"
							color="success"
							onClick={() => {
								setIsEdit(!isEdit);
								onUpdateClick(data, index);
							}}
						>
							Update
						</Button>
						<Button size="small" color="error" onClick={handleEditClick}>
							Cancel
						</Button>
					</div>
				</CardActions>
			</Card>
		</Grid>
	);
};

export default User;
