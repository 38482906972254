import React, { useEffect, useState } from 'react';
import {
	TextField,
	Button,
	CssBaseline,
	Container,
	Box,
	Avatar,
	Typography,
	createTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const defaultTheme = createTheme();

const Login = () => {
	const navigate = useNavigate();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		localStorage.setItem('isLogin', 'false');
	});
	const handleSubmit = (event) => {
		event.preventDefault();

		if (username && password) {
			if (username === 'admin' && password === 'ozgun1234!') {
				navigate('/dashboard');
				localStorage.setItem('isLogin', 'true');
			} else alert('Error, Incorrect login creditial!!!');
		}
	};

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Admin
					</Typography>
					<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							required
							fullWidth
							id="username"
							label="Username"
							name="username"
							autoFocus
							onChange={(e) => setUsername(e.target.value)}
							value={username}
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							onChange={(e) => setPassword(e.target.value)}
							value={password}
						/>

						<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
							Sign In
						</Button>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
		/*     <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Box sx={{ bgcolor: "#cfe8fc", height: "50vh" }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid
              container
              spacing={1}
              sm={12}
              justifyContent="center"
              alignItems="center"
            >
              <Grid container item justifyContent="center">
                <h3>Login</h3>
              </Grid>
              <Grid container item justifyContent="center">
                <TextField
                  label="Username"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  variant="outlined"
                  color="secondary"
                  type="string"
                  sx={{ mb: 3 }}
                  fullWidth
                  value={username}
                  error={usernameError}
                />
              </Grid>
              <Grid container item justifyContent="center">
                <TextField
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  variant="outlined"
                  color="secondary"
                  type="password"
                  value={password}
                  error={passwordError}
                  fullWidth
                  sx={{ mb: 3 }}
                />
              </Grid>
              <Grid container item justifyContent="center">
                <Button variant="outlined" color="secondary" type="submit">
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </React.Fragment>
 */
	);
};

export default Login;
