import React from "react";

const nopage = () => {
  return (
    <div>
      <div>nopage</div>
      
    </div>
  );
};

export default nopage;
